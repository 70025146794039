$(document).on('turbolinks:load', function() {

  $(document).on('scroll', function(e) {
    if($('#header_shop_show').length) {
      let header_opacity = (1 - ($(document).scrollTop() / ($('#header_shop_show').find('.shop_picture').height() - 80)))
      $('#header_shop_show').find('.shop_picture').css('opacity', header_opacity)
      $('.shop_fixed_white_background').css('opacity', 1 - header_opacity)
      $('.shop_fixed_name').toggle(header_opacity < 0)
      $('.shop_fixed_categories').toggleClass('d-flex', header_opacity < 0)
    }
  })
})

$(document).on('click', '.shop_favorite_container p', () => {
  $('.shop_favorite_container').toggleClass('active')
})

$(document).on('turbolinks:load', function() {
  if($('#shop_category_header').length) {
    $('.shop_fixed_categories').scrollCenter('.active', 10)
  }
})

$(document).on('click', '.submit_cart_button', function() {
  $.get('/check_current_user', function(data) {
    if(data.current_user) {
      $('body').addClass('loading')
      Turbolinks.visit(data.url)
    } else {
      $.get('/users/sign_in?before_checkout=true')
    }
  })
})
