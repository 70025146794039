require('bootstrap/js/dist/modal');

window.initModal = function(custom_content, empty_custom_content) {
  $('.modal-content').removeClass('modal_item_form')
  $('#modal_menu').modal('show')
}

export function askClosingModal() {
  $('.modalception').css('backgroundColor', $('.main-menu').css('backgroundColor'))
  $('.modal-content').addClass('closing-alert')
  $('.modalception').slideDown(300)
}

$(document).on('click', '[data-toggle=modal]', function(event) {
  initModal($(event.currentTarget).data().custom, true)
})

$(document).on('click', '[data-loading]', function() {
  $('body').addClass('loading')
})

$(document).on('turbolinks:load', function() {
  $('body').removeClass('loading')
})
