import { MainController } from './main_controller';

export default class ShopindexController extends MainController {
  connect() {
    var input = document.getElementById('mobile-browser-shop-index__main__search-container__input')
    var options = {
      types: ['geocode'],
      language: 'fr'
    };
    var autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.setComponentRestrictions({
      country: ["fr", 'be'],
    });    
  }
  click(e) {
    $('.loading_app_container').removeClass('d-none')
    Turbolinks.visit($(e.currentTarget).data().href)
  }

  searchChanged(e) {
    setTimeout(() => {
      $.post({
        url: '/shops/search_location',
        data: {address: $('#mobile-browser-shop-index__main__search-container__input').val()}
      })
    }, 500)
    if($('#mobile-browser-shop-index__main__search-container__input').val().length) {
      $('#mobile-browser-shop-index__main__address').text(`Les boutiques aux environs de ${$('#mobile-browser-shop-index__main__search-container__input').val()}`)
    } else {
      $('#mobile-browser-shop-index__main__address').text("Toutes les boutiques")
    }
  }
}
