export class ProductVariationHandler {
  constructor(productVariationInput) {
    this.productVariationInput = productVariationInput
  }
  populate(unitId, container, destination, basketProduct) {
    this.container = container
    this.destination = destination
    this.destination.html('')
    this.productVariations = this.container.data().pvs.filter((i, e) => i.unitId == unitId)
    this.selectedProductVariations = this.productVariations
    this.basketProduct = basketProduct
    this.unitId = unitId
    this.groupAttributeOrders = container.data().groupAttributeOrders
    console.log(container.data())
    this.groupAttributes = this.container.data().groupattribute.filter((e) => e.attributs.some((a) => {
      return this.productVariations.some((pv) => { return pv.attributeCollectionId.includes(a.id) })
    })).sort((a, b) => {
      if(this.groupAttributeOrders[unitId] && !isNaN(this.groupAttributeOrders[unitId][a.id]) && !isNaN(this.groupAttributeOrders[unitId][b.id])) {
        return this.groupAttributeOrders[unitId][a.id] > this.groupAttributeOrders[unitId][b.id] ? 1 : -1
      } else {
        return 1
      }
    })

    $.each(this.groupAttributes, (i, groupAttribute) => { this.addGroupAttribute(groupAttribute)})
    if(basketProduct) {
      this.selectedProductVariations = this.productVariations.filter((pv) => {
        return pv.id == basketProduct.productVariationId
      })
    }
  }

  addGroupAttribute(groupAttribute) {
    let attributs = groupAttribute.attributs.filter((a) => {
      return this.productVariations.some((pv) => { return pv.attributeCollectionId.includes(a.id)})
    })
    this.destination.append(`
      <fieldset class="form-group radio_buttons optional basket_product_attribute w-100" id="basket_product_group_attribute_${groupAttribute.id}">
        <legend class='basket-form-section__title'>Choix de ${groupAttribute.name}</legend>
        <input type="hidden" name="basket_product[attributeCollectionId][${groupAttribute.group_type}[groupAttribute_${groupAttribute.id}]" value="" autocomplete="off">
        ${this.addAttributes(attributs, groupAttribute)}
      </fieldset>
    `)
  }

  attributeSelected(attribute) {
    let selectedAttributs = this.container.find('.product_variations_container :checked').map((i, e) => { return $(e).val()}).get()
    //console.log(selectedAttributs)
    this.selectedProductVariations = this.productVariations.filter((pv) => { 
      return selectedAttributs.every((a) => {
        return pv.attributeCollectionId.includes(a)
      })
    })
    let break_loop = false
    $.each($('.basket_product_attribute').not($(attribute).parents('.basket_product_attribute')), (i, attribut_container) => {
      if(break_loop) {
        //return false
      }
      $.each($(attribut_container).find('.form-check-input'), (i, input) => {
        if(break_loop) {
          //return false
        }
        let isIncluded = this.selectedProductVariations.some((pv) => { return pv.attributeCollectionId.includes($(input).val()) })
        if($(attribut_container).find(':checked').length == 0) {
          $(input).prop('disabled', !isIncluded)       
        } else {
          if(!isIncluded && $(input).prop('checked')) {
            $(input).prop('checked', false)
            $(input).prop('disabled', true)
            break_loop = true
            this.attributeSelected()
          } else if(isIncluded) {
            $(input).prop('disabled', false)
          }
        }

      })
    })
  }

  addAttributes(attributs, groupAttribute) {
    let html = ''
    $.each(attributs, (i, attribute) => {
      let checked
      if(attributs.length == 1 || (this.basketProduct && this.basketProduct.unitId == this.unitId && (this.basketProduct.compositionAttributes.includes(attribute.id) || this.basketProduct.fabricationAttributes.includes(attribute.id)))) {
        checked = true
      }
      html += `
        <div class="form-check custom-checkbox custom-control">
          <input class="form-check-input radio_buttons optional custom-control-input" type="radio" value="${attribute.id}"
            name="basket_product[attributeCollectionId][${groupAttribute.group_type}][groupAttribute_${groupAttribute.id}]" id="basket_product_attribute_${attribute.id}"
            data-action="basketproduct#attributeSelected" ${checked ? 'checked' : ''}
          >
          <label class="form-check-label collection_radio_buttons" for="basket_product_attribute_${attribute.id}">
            ${attribute.name}
          </label>
        </div>
      `
    })
    return html
  }

  selectVariation() {
    if(this.selectedProductVariations && this.selectedProductVariations.length == 1) {
      this.productVariationInput.val(this.selectedProductVariations[0].id)
      return this.selectedProductVariations[0].price
    } else {
      this.productVariationInput.val("00000000-0000-0000-0000-000000000000")
      return null
    }
  }
}