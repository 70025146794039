import { Controller } from 'stimulus';
import { flashMessage } from '../shared/notify';
export default class FavsController extends Controller {
  openShop(e) {
    let type = $(e.currentTarget).find('.favorite_container').data().shoppable.split('_')[0] == 'LocalShop' ? 'Shop' : 'PickUpLocation'
    let uuid = $(e.currentTarget).find('.favorite_container').data().uuid
    console.log(JSON.stringify({type: type, uuid: uuid}))
    try {
      window.flutter_inappwebview.callHandler('showFav', JSON.stringify({type: type, uuid: uuid}))
    } catch {
      flashMessage("La fonction showFav.postMessage(type, uuid); n'est pas définie", '', 'danger')
    }
  }
}
