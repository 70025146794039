import { Controller } from 'stimulus';
import { flashMessage } from '../shared/notify';
export default class EditaccountController extends Controller {

  connect() {
    $('.edit_user').submit((e) => {
      this.full_name_valid = this.check_input($('#user_full_name'))
      this.address_valid = this.check_input($('#user_address'))
      this.postal_code_valid = this.check_input($('#user_postal_code'))
      this.city_valid = this.check_input($('#user_city'))
      if(this.address_valid && this.city_valid && this.full_name_valid && this.postal_code_valid) {
        // bouh
      } else {
        e.preventDefault()
      }
      setTimeout(() => {
        $('.edit_user .submit_button').prop('disabled', false)
      }, 100)

    })
  }

  submit_form() {
    $('.accountedit_form').submit()
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  check_email(input) {
    input.removeClass('is-valid is-invalid')
    if(input.val() == this.original_email) {
      input.addClass('is-valid')
      return true
    }
    if(input.val().length == 0) {
      input.addClass('is-invalid')
      this.email_valid = false
      input.next().text("Veuillez renseigner une adresse email")
      return false;
    }

    if(!this.validateEmail(input.val())) {
      input.addClass('is-invalid')
      this.email_valid = false
      input.next().text('Veuillez renseigner une adresse email valide')
      return false;
    }
    $.ajax({
      type: 'POST',
      url: '/check_email_223e1821-8d58-4af4-9ad8-734decec7d9a',
      data: {email: input.val()}
    }).done((data) => {
      if(data.exists) {
        input.addClass('is-invalid')
        this.email_valid = false
        input.next().text("Cette adresse email est déjà utilisée")
        return false;
      } else {
        this.email_valid = true
        input.addClass('is-valid')
        return true;
      }
    })
  }

  email_change(e) {
    let input = $(e.currentTarget)
    input.removeClass('is-valid is-invalid')
    this.check_email(input)
  }

  check_input(input) {
    input.toggleClass('is-valid', input.val().length > 0)
    input.toggleClass('is-invalid', input.val().length == 0)
    return input.val().length > 0
  }

  address_change(e) {
    this.address_valid = this.check_input($(e.currentTarget))
  }

  full_name_change(e) {
    this.full_name_valid = this.check_input($(e.currentTarget))
  }

  city_change(e) {
    this.city_valid = this.check_input($(e.currentTarget))
  }

  postal_code_change(e) {
    this.postal_code_valid = this.check_input($(e.currentTarget))
  }
}
