// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()

window.jQuery = $;
window.$ = $;

require('shared/modal')
import 'controllers';
require('home/navbar')

require('shared/notify')
require('shared/init')
require('home/shop_show')
require('libs/scroll_center')
require('shared/favorite_shops')
require('shared/flutter')
window.moment = require('moment');
import 'moment/locale/fr';


//import { loadCoords } from '../home/map';
import { deliveryModal } from 'shared/delivery_modal';
import { jwtChecker } from 'shared/jwt_parser';

$(document).on('turbolinks:load', function() {
  jwtChecker()
  if($(window).width() > 768) {
    let origin = {
      'mobilestaging.vrai.app': 'webstaging.vrai.app',
      'm.vrai.app': 'web.vrai.app',
      'pprd.vrai.app': 'webpprd.vrai.app'
    }
    window.location = `https://${origin[window.location.host]}${window.location.pathname}${window.location.search}`
  }
  $('body').removeClass('loading')
  if($('#map').length) {
    loadCoords()
  }
})



$(document).on('click', '.select_delivery_params', (e) => {
  $.get('/cart/edit_delivery')
})

$(document).on("turbolinks:before-cache", function() {
  $('body').removeClass('loading')
})



$(document).on('click', 'body', function(e) {
  var container = $("#cart_content");
  // if the target of the click isn't the container nor a descendant of the container
  if (!container.is(e.target) && container.has(e.target).length === 0 && $(e.target).get(0).id != 'icon_cart')
  {
    container.hide();
  }
})


