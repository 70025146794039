import { flashMessage } from './notify';

$(document).on('click', '.favorite_container', (e) => {
  e.preventDefault()
  e.stopPropagation();
  let picture = $(e.currentTarget)
  if(!localStorage.getItem('jwt')) {
    flashMessage('', 'Vous devez être connecté pour pouvoir ajouter une boutique à vos favoris !', 'warning')
    return false
  }
  picture.toggleClass('active')
  $.get(`/shops/toggle_favorite?shoppable=${picture.data().shoppable}`)
  if(picture.hasClass('active')) {
    flashMessage('', `La boutique ${picture.data().name} a bien été ajoutée à vos favoris !`, 'success')
  } else {
    flashMessage('', `La boutique ${picture.data().name} a été enlevée de vos favoris !`, 'success')
    if($(e.currentTarget).parents('#favs_index').length) {
      $(e.currentTarget).parents('.shop_card_container').remove()
    }
  }
})
