import { Controller } from 'stimulus';
export default class ConfirmationController extends Controller {

  connect() {}

  submit(e) {
    this.check_full_name($('#user_full_name'))
    if(this.full_name_valid) {

    } else {
      e.preventDefault()
    }
    setTimeout(() => {
      $('.edit_user .submit_button').prop('disabled', false)
    }, 100)

  }

  check_full_name(input) {
    input.removeClass('is-valid is-invalid')
    if(input.val().length > 0) {
      this.full_name_valid = true
      input.addClass('is-valid')
    } else {
      this.full_name_valid = false
      input.addClass('is-invalid')
      input.next().text("Veuillez renseigner votre prénom et votre nom")
    }
  }

  full_name_change(e) {
    this.check_full_name($(e.currentTarget))
  }


}
