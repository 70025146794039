//if (navigator.serviceWorker) {navigator.serviceWorker.register('/service-worker.js', { scope: './' }).then(function(reg) {});}

$(document).on('turbolinks:load', function() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  $('body').fadeIn(1000)
})

window.onresize = function(event) {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
