import { Controller } from 'stimulus';
import latinize from 'latinize';
import { productHeader } from '../shared/product_header';
import PerfectScrollbar from 'perfect-scrollbar';
import { flashMessage } from '../shared/notify';
import { convertColor } from '../shared/psbc';
export default class ShopController extends Controller {

  connect() {
    convertColor($(this.element).data().color)
    if($(this.element).data().url.length) {
      $('#checkout_modal').modal('show')
      console.log($(this.element).data().url)
      $.ajax({url: $(this.element).data().url, dataType: 'script'});
    }
    if(localStorage.getItem('edit_product')) {
      setTimeout(() => {
        try {
          let item = JSON.parse(localStorage.getItem('edit_product'))
          localStorage.removeItem('edit_product')
          productHeader($(`[data-item-id="${item.smartId}"]`))
          $.get(`/basket_products/${item.cartId}/edit`)
        } catch (error) { }
      }, 500)

    }

    /**
    $.each($('.shop_show_items_container'), (i, e) => {
      new PerfectScrollbar($(e).get(0))
    })
    this.category_scroll = new PerfectScrollbar($('#scroll_categories_container').get(0))
    **/
    this.initScroll()


    if($('#mobile-shop-show').data().product && $(`#shop_product_${$('#mobile-shop-show').data().product}`)) {
      let product = $(`#shop_product_${$('#mobile-shop-show').data().product}`)
      productHeader(product)
      $.get(`/${product.data().type}/new?item_id=${product.data().itemId}`)
    }
  }

  clickSellable(e) {
    let ele_data = $(e.currentTarget).data()
    if(!ele_data.itemId) { return false; }
    productHeader($(e.currentTarget))
    $.get(`/${ele_data.type}/new?item_id=${ele_data.itemId}&shoppable=${$(this.element).data().shoppable}`)
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
  }

  initScroll() {
    const el = document.querySelector("#shop-show__sticky")
    const observer = new IntersectionObserver( 
      ([e]) => {
        e.target.classList.toggle("shop-show__sticky--pinned", e.intersectionRatio < 1)
      },
      { threshold: [1] }
    );

    observer.observe(el);    
    this.first_visible = undefined

    $(document).on('scroll', () => {
      if($('.fixed_shop_category').length > 0) {
        return
      }
      $('.shop-show__categories__item').removeClass('active')
      if($('#shop-show__header').innerHeight() < $(document).scrollTop()) {
        $.each($('.shop-show__section--category'), (index, element) => {
          if($(element).offset().top > $(document).scrollTop()) {
            this.first_visible = $(element)
            return false
          }
        })
        let prev = $(`#category_header_${this.first_visible.data().category}`).prevAll().map((e, i) => {return $(i).innerWidth()})
        let offset = prev.get().reduce((partialSum, a) => partialSum + a, 0) + $('.search_shop_container').innerWidth()
        $(`#category_header_${this.first_visible.data().category}`).addClass('active')
        $('#shop-show__sticky__categories').animate({
          scrollLeft: offset + (prev.length * 4) - 8
        }, 0);
        //this.category_scroll.update()
      } else {
        $('.shop-show__categories__item').eq(0).addClass('active')

        $('#shop-show__sticky__categories').animate({
          scrollLeft: 0
        }, 0);
        //this.category_scroll.update()
      }
      $('')

    })
  }

  toggleSearch() {
    if($('#shop-show__sticky__search-container').hasClass('shop-show__sticky__search-container--opened')) {
      $('#shop-show__sticky__search-container').removeClass('shop-show__sticky__search-container--opened')
      $('#shop-show__sticky__search-container input').val('')
      this.show_all()
    } else {
      $('#shop-show__sticky__search-container').addClass('shop-show__sticky__search-container--opened')
      $('#shop-show__sticky__search-container input').focus()
    }
    
  }  

  show_all() {
    $('.shop-show__categories__item').removeClass('active')
    $('.shop_show_item_card').show()
    $(`.shop-show__categories__item[data-category-id="all"]`).addClass('active')
    $('.shop-show__section').toggle(true)
    $('.fixed_shop_category').removeClass('fixed_shop_category')
    $('.shop-show__section__items-container').removeClass('wrapping')
  }

  select_category(e) {
    let id = $(e.currentTarget).data().categoryId
    this.show_all()

    $('#shop-show_search_input').val('')
    $('hr.shop-show__horizontal-separation').show()
    $('.shop-show__thumbnail-line').show()
    $('.shop_shopw_section__see-all').show()
    if(id != 'all') {
      $('.shop-show__section').hide()
      $(e.currentTarget).addClass('fixed_shop_category')
      $('hr.shop-show__horizontal-separation').hide()
      $('.shop-show__thumbnail-line').hide()
      $('.shop_shopw_section__see-all').hide()
      let section = $(`#shop_section_${id}`)
      console.log(id)
      section.show()
      section.get(0).scrollIntoView();
      section.find('.shop_show_item_card').show()
      section.find('.shop-show__section__items-container').addClass('wrapping')
    } else {
      this.show_all()
    }
    $('.shop-show__categories__item').removeClass('active')
    $(`.shop-show__categories__item[data-category-id="${id}"]`).addClass('active')
  }


  search(e) {
    $('.shop-show__section').show()
    let value = latinize($(e.currentTarget).val().toLowerCase())
    if(value.length > 0) {
      $('.shop-show__thumbnail-line').hide()
      // window.scrollTo(0, 0);
      $('.shop_show_item_card').filter(function() {
        return $(this).toggle(latinize($(this).data().filter.toLowerCase()).indexOf(value) > -1)
      });
    } else {
      this.show_all()
    }

    $('.shop-show__section').filter(function() {
      $(this).toggle($(this).find('.shop_show_item_card:visible').length != 0)
    })

    $('.shop-show__section--category:visible').get(0).scrollIntoView()

  }

  blursearch(e) {
    if($(e.currentTarget).val().length == 0) {
      $('#shop-show__sticky__search-container').removeClass('shop-show__sticky__search-container--opened')
    }
  }

  showHours() {
    let url = `/shops/${$(this.element).data().id}/show_hours`
    if($(this.element).data().type.length) {
      url += `?shop_type=true`
    }
    $.get(url)
  }

  showDescription() {
    let url = `/shops/${$(this.element).data().id}/show_description`
    if($(this.element).data().type.length) {
      url += `?shop_type=true`
    }
    $.get(url)
  }  

  showVitrine(e) {
    let url = `/shops/${$(this.element).data().id}/show_vitrine?vitrine_id=${$(e.currentTarget).data().id}`
    if($(this.element).data().type.length) {
      url += `&shop_type=true`
    }
    $.get(url)    
  }

  showAddress(e) {
    let url = `/shops/${$(this.element).data().id}/show_address`
    if($(this.element).data().type.length) {
      url += `?shop_type=true`
    }
    $.get(url)     
  }

}
