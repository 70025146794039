require('bootstrap/js/dist/modal');

import { askClosingModal } from 'shared/modal'

export function deliveryModal(e) {
  let html = `
    <div class='delivery_modal' data-controller='deliverymodal' data-delivery="${$(e.currentTarget).data().delivery}" data-id="${$(e.currentTarget).get(0).id}">
      <p class='font-bold text-13 text_c99'>Choisissez votre date de retrait :</p>
      <div class='position-relative mb-2'>
        <select class='delivery_date_select w-100' data-action="deliverymodal#dateChanged"></select>
      </div>
      <div class='position-relative'>
        <select class='delivery_minute_select w-100'></select>
      </div>
      <div class='d-flex justify-content-end align-items-center mt-4'>
        <p class='mb-0 delivery_close_modal' data-action="click->deliverymodal#closeModal">Annuler</p>
        <p class='mb-0 ml-4 btn btn-primary font-bold' data-action="click->deliverymodal#validDate">Valider</p>
      </div>
    </div>

  `
  $('.modalception').html(html)
  if($('.order_user_name').length) {
    $('.modal-content').html('')
  }
  askClosingModal()
  if(!$('.modal-open').length) {
    $('#modal_menu').removeClass('modal-right')
    $('#modal_menu').modal('show')
    $('.modal-content').css('background', 'transparent')
    setTimeout(function(){ $('#modal_menu').addClass('modal-right') }, 200);

  }


}
