export function productHeader(product) {
  $('.loading_app_container').removeClass('d-none')
  let footer_controller = product.data().type == 'basket_menus' ? 'menu' : 'product'
  $('#modal_menu').modal('show')
  $('#modal_menu .modal-content').html(`
    <div class='basket_item_modal' data-controller='basketproductheader'>
      <div class='basket_item_modal_header'>
        <div class="basket_item_head position-relative">
          <img src='${product.find('.shop_show_product_picture').attr('src')}'
        </div>
        <div class="basket_item_informations_container">
          <div class="basket_product_availability"></div>
          <div class='basket_item_title'>
            <p class='mb-0 font-bold text-13 basket_item_title'>${product.find('.item_name').text()}</p>
            <p>${product.find('.item_price').html()}</p>
          </div>
          
          <p class='basket_product_modal_description'></p>
          <p class='basket_product_modal_description_show_more' style='display: none;' data-action="click->basketproductheader#showMore">Plus d’infos<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.49998 3.33331V12.6666M3.83331 7.99998H13.1666" stroke="#6B7281" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
</svg> </p>
          <p class="text-9 special_text recap_title mb-0 mt-2 font-bold" style="display: none;">Récapitulatif</p>
        </div>
      </div>

      <div class="form-slider position-relative">

      </div>
    </div>
    <div id="basket-form-footer" data-controller='basket${footer_controller}footer'></div>
  `)
}
