//"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2Vy,SWQiOiIzOTlmOWM2NS1kZjZmLTQ4YTctOWE5Ni04YzQxNDZiNzVlYzciLCJzZXNzaW9uSWQiOiI2M2I1OWUxM2RiNWNkOGE4YTE3YSIsImV4cCI6MTY3Mjg0ODg3Mn0.vCQAkgyHyzGvIh9xeNxNpCjZvzJmqUGMjVoo928qJqQ"
import { Client, Account, ID } from 'appwrite';

function jwtParser(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function jwtChecker() {
  if(!localStorage.getItem('jwt')) { return false }
  let decoded = jwtParser(localStorage.getItem('jwt'))
  if((decoded.exp - moment().unix()) < 300) {
    let appWriteClient = new Client();
    appWriteClient.setEndpoint(process.env.apiEndpoint).setProject(process.env.projectId);
    let account = new Account(appWriteClient);

    window.flutter_inappwebview.callHandler('getJwt', '').then(function (jwt) {
      localStorage.setItem('jwt', jwt)
      $.ajax({
        method: 'POST',
        url: '/appwrite/signin_user',
        data: {jwt: jwt, skip_init: true}
      })
    }, function (error) {
      console.log(error);
      logoutUser()
    });
  }
}
