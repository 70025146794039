import { MainController } from './main_controller';
import { flashMessage } from '../shared/notify';
import { jwtChecker } from '../shared/jwt_parser';

export default class CartrecapController extends MainController {
  connect() {
    this.setButtonMessage()
  }

  previous() {
    $('.loading_app_container').removeClass('d-none')
    if($(this.element).data().hasShipment) {
      $.get('/shipment')
    } else {
      $.get('/cart/reload')
    }
  }

  updateComment(e) {
    let comment = $('#modal-cart-recap__comment__textarea').val().trim()
    $.ajax({
      method: 'PUT',
      data: {comment: comment},
      url: '/cart/update_comment'
    }).done(() => {
      flashMessage('', 'Le commentaire de la commande a bien été mis à jour', 'success')
      this.setButtonMessage()
    })

  }

  setButtonMessage() {
    $('.modal-cart-recap__comment .modal-cart-container__submit-button').text(
      $('#modal-cart-recap__comment__textarea').val().trim().length > 0 ? "Modifier le commentaire" : "Ajouter ce commentaire"
    )
  }

  async send_cart() {
    $('.loading_app_container').removeClass('d-none')
    window.flutter_inappwebview.callHandler('getJwt', '').then(function (jwt) {
      localStorage.setItem('jwt', jwt)
      $.ajax({
        method: 'POST',
        url: '/appwrite/signin_user',
        data: {jwt: jwt, skip_init: true}
      }).done(() => {
        $.get('/check_current_user', (data) => {
          if(data.current_user) {
            if(!data.verified) {
              $('.loading_app_container').addClass('d-none')
              $('#modal_menu .modal-content').html(`<div class='p-4' data-controller='cartverificationmodal'></div>`)
              //flashMessage('', "Votre compte n'est pas vérifié. Merci de cliquer sur le lien de vérification transmis par mail lors de la création de votre compte.", 'warning')
            } else {
              $.ajax({url: '/checkout/new', dataType: 'script'});
            }
          } else {
            try {
              //showLogin.postMessage('');
              window.flutter_inappwebview.callHandler('showLogin', '')
            } catch {
              flashMessage("La fonction window.flutter_inappwebview.callHandler('showLogin', '') n'est pas définie", '', 'danger')
            }
          }
        })    
      })
    })
  }  
}