import { numberToEuro } from '../shared/number_to_euro';
import { svgQuantityMinus, svgQuantityPlus } from '../shared/svg_icons';

export class SupplementSelectionBuilder {
  constructor(groupSupplement, datas, mixable, baseName) {
    this.groupSupplement = groupSupplement
    this.datas = datas
    this.maxQuantity = datas.localGroupSupplements[groupSupplement.id] ? parseInt(datas.localGroupSupplements[groupSupplement.id].quantity) : 0
    this.mixable = mixable
    this.baseName = baseName
    console.log('baseName', baseName)
    this.controller_name = baseName == "basket_product" ? 'basketproduct' : 'basketmenu'
  }

  build() {
    let title
    if(this.mixable) {
      title = 'Suppléments'
    } else if(this.datas.controller == "dishsetselection") {
      title = `${this.groupSupplement.name} pour ${this.datas.name}`
    } else {
      title = this.groupSupplement.name
    }
    let header = `<p class='basket-form-section__title basket-form-section__title--supplement'>${title}</p>`
    if(this.maxQuantity > 0) {
      header += `<p class='basket-form-mixable-price-list'>(max ${this.maxQuantity})</p>`
    }
    return `
      <div class='basket-form-section' data-max-quantity="${this.maxQuantity}">
        ${header}
        ${this.populateSupplements()}
      </div>
    `
  }

  populateSupplements() {
    let html = ''
    $.each(this.datas.supplement[this.groupSupplement.id], (i, supplement) => {
      let key = `${this.baseName}[supplement_selections_attributes][${supplement.id}]`
      let header = "<p class='basket-form-customize__element-name-container'>"
/*      let picture = $('#form_slider').data().supplementPictures[supplement.uniqueId]
      if(picture) {
        header = `<img src="${picture}" />`
      }*/
      header += `<span class='basket-form-customize__element-name'>${supplement.name}</span>`
      if(supplement.pricingImpact > 0) {
        header += `<span class="basket-form-customize__element-detail"> + ${numberToEuro(supplement.pricingImpact)}</span>`
      }
      header += "</p>"
      let value = 0
      if(this.datas.supplementSelections && this.datas.supplementSelections[supplement.id]) {
        value = this.datas.supplementSelections[supplement.id]
      }
      html += `
        <div class='d-none'>
          <input name="${key}[shopId]" value="${this.datas.shopId}" />
          <input name="${key}[supplementId]" value="${supplement.id}" />
        </div>

        <div class="basket-form-customize">
          ${header}
          <div class="basket-form-quantity-buttons">
            <div data-action="click->${this.controller_name}#supplementChanged" data-factor="minus" class='basket-form-quantity-buttons__decrease'>
              ${svgQuantityMinus()}
            </div>
            <input
              class="form-control numeric float optional" type="number"
              readonly
              name="${key}[quantity]"
              value="${value}"
              data-price="${supplement.pricingImpact}"
              data-target="supplementselection.input"
              onfocus="this.blur()"
              data-name='${supplement.name}'
            />
            <div data-action="click->${this.controller_name}#supplementChanged" data-factor="plus" class='basket-form-supplement-selections__plus-button'>
              ${svgQuantityPlus()}
            </div>
          </div>
        </div>        
      `
    })
    return html
  }
}